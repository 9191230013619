import { FormVersions } from 'components/DemoForm/demoFormJson';
import Image from 'next/image';
import React from 'react';
import { imageBasePath } from 'utils/imageUtils';

type Props = {
  currentFormStep: number;
  onBackClick: () => void;
  formVersion: string | string[] | undefined;
};
const getHeadingText = (currentFormStep: number) => {
  if (currentFormStep === 1) return 'Book your IELTS MasterClass';
  else if (currentFormStep === 2) return 'Almost there';
  return 'One last step';
};
const FormHeading: React.FC<Props> = ({
  currentFormStep,
  onBackClick,
  formVersion,
}) => {
  return formVersion === FormVersions.ONBOARDING_QUESTION_ADD ? (
    <div>
      <div className="relative">
        {' '}
        {currentFormStep > 1 && (
          <div
            className="flex items-center py-7  cursor-pointer absolute md:left-24 left-4 "
            onClick={onBackClick}
          >
            <Image
              src={`${imageBasePath}/avataran_assets/images/course/back_arrow3.svg`}
              alt="arrow"
              width={20}
              height={20}
            />
            <p className="!font-[Poppins] font-semibold text-[#4B496E] pl-2 hidden md:block">
              Go back
            </p>
          </div>
        )}
        <p className=" text-[18px] md:text-[24px] font-[Poppins] font-bold w-fit mx-auto py-6 text-[#100E3A]">
          {getHeadingText(currentFormStep)}
        </p>
      </div>
      <hr className="bg-[#EDEEFF] border-[#EDEEFF]  border-[1px] mt-2 mb-10" />
    </div>
  ) : (
    <div>
      {currentFormStep > 1 && (
        <div
          className="max-w-row mx-auto text-gray-1741 flex items-center pt-4 md:pl-0 pl-2 cursor-pointer"
          onClick={onBackClick}
        >
          <img
            src={`${imageBasePath}/avataran_assets/icons/back_arrow1.svg`}
            alt="arrow"
            width={20}
            height={20}
          />
          <p className="text-font16 hidden md:inline-block pl-2">BACK</p>
        </div>
      )}
      <div className="flex flex-col w-full gap-8 mt-10">
        <div>
          <h2 className="font-bold text-[#0A0926] text-xl text-center">
            <p className="font-bold ">
              Join Free{' '}
              <span className="bg-gradient-to-r from-[#635EFF] via-[#663CFF] to-[#5200FF]  text-white px-3 rounded-3 inline-block skew-x-[-10deg]">
                <span className="inline-block skew-x-[10deg]">IELTS</span>
              </span>{' '}
              MasterClass
            </p>
          </h2>
        </div>
        <hr className="bg-[#EDEEFF] border-[#EDEEFF]  border-[1px] mt-2 mb-10" />
      </div>
    </div>
  );
};

export default FormHeading;
