import React, { FunctionComponent } from 'react';

type Props = {
  width?: number;
  color?: string;
  bgColor?: string;
};

const LinearProgressBar: FunctionComponent<Props> = ({
  width,
  color,
  bgColor,
}) => {
  const widthValue = width ? width.toString() + '%' : 0;
  return (
    <div
      className={`w-full ${
        bgColor ? `bg-[${bgColor}]` : 'bg-transparent'
      } h-2.5`}
    >
      <div
        className={`h-2.5 rounded-r-full`}
        style={{
          width: widthValue,
          backgroundColor: color ? color : '#7DBC7B',
        }}
      />
    </div>
  );
};

export default LinearProgressBar;
