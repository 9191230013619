import api from 'services/api';

export const fetchLiyRedirectData = async () => {
  try {
    const res = await api.get(
      `${process.env.NEXT_PUBLIC_CMS_URL}/redirect-form-filled-users-to-liy`,
    );
    return res.data;
  } catch (error) {
    console.error(error);
  }
};
