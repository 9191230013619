import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Form } from 'react-final-form';

import {
  demoAliasQueryConstant,
  RenderFields,
} from 'components/DemoForm/utils';

import {
  autonextDecision,
  forceAutonextDecision,
  indexCal,
  nameExtraSpacetriming,
} from 'components/DemoForm/utils';
import { useRouter } from 'next/router';
import { navRoutes } from 'page_routes';
import {
  trackIeltsClick,
  trackIeltsFormAsync,
  trackPageV2,
} from 'scripts/segment';
import { getQueryParamStringFromObject } from 'utils/utm_utils';
import { getUtmData } from 'utils/utm_utils';
import { Button } from 'components/LSRevamp/common/Button2';
import { cloneDeep } from 'utils/utils';
import { IeltsFormIds, LeadSources } from 'DataMapper/DemoForm.tsx';
import LinearProgressBar from 'components/LandingPagesComponents/DemoProgressiveFormWrapper/LinearProgressBar';
import Image from 'next/image';
import { imageBasePath } from 'utils/imageUtils';
import { ieltsPageName } from 'utils/events-utils';
import useLocation from 'hooks/useLocation';
import { countryData, CountryDto } from '@leapfinance/auth-kit';
import { redirectOutFromIframe } from 'utils/url_utils';
import {
  countryOptions,
  validatePhoneNumberOnCountry,
} from 'components/DemoForm/countriesDetails';
import { CountryCode } from 'DataMapper/CountryCode';
import FormHeading from 'components/LandingPagesComponents/DemoProgressiveFormWrapper/FormHeading';
import {
  FormVersions,
  SIGN_IN_PURPOSE,
  WHEN_IELTS,
} from 'components/DemoForm/demoFormJson';
import { fetchLiyRedirectData } from 'components/LandingPagesComponents/DemoProgressiveFormWrapper/liyRedirectApi';
import { ValidationErrors } from 'final-form';
import { ieltsPlusApi } from 'components/Profile/IeltsProfile/IeltsPlus/redux/IeltsPlusApi';
import { getCountryFromCountryCode, getUserCountry } from 'utils/countryUtils';
type PageProps = {
  isLoggedIn: undefined | boolean;
  loggedInNumber: string | number | null;
  formJson: any;
  prefilledData?: Record<string, any>;
  onSubmitForm?: (val: any) => void;
};
export const findFieldInFormJson = (fieldName: string, formJson: any) => {
  return !!formJson.fields.find((field: any) => {
    return field.fieldValue == fieldName;
  });
};
export const customValidationForCountryCode = (values: any, formJson: any) => {
  if (
    findFieldInFormJson('phone', formJson) &&
    findFieldInFormJson('countryCode', formJson)
  ) {
    const phoneNumber = values['phone'];
    const countrySelected = values['countryCode'];
    if (
      phoneNumber &&
      countrySelected &&
      !validatePhoneNumberOnCountry(phoneNumber, countrySelected)
    ) {
      return { phone: 'Please enter correct phone number' };
    }
  }
  return {};
};
const DemoProgressiveForm: FunctionComponent<PageProps> = ({
  isLoggedIn,
  loggedInNumber,
  formJson,
  prefilledData,
  onSubmitForm,
}) => {
  const router = useRouter();
  const [currentFormStep, setCurrentFormStep] = useState(1);
  const { userCountry } = useLocation();
  const formVariant = router.query.form_version as string;

  const [duplicateFormValues, setDuplicateFormValues] = useState<any>({});
  const [formValues, setFormValues] = useState<any>({});
  const [isClickingBack, setIsClickingBack] = useState<boolean>(false);
  const delayForStepChange = useRef<number>(0);
  const { leadSource: leadSourceFromQuery } = router.query;
  const leadSource =
    (Array.isArray(leadSourceFromQuery)
      ? leadSourceFromQuery[0]
      : leadSourceFromQuery) || 'IELTS Landing Page';

  const getIeltsPageName = (step: number) => {
    if (step == 1) {
      if (formVariant == FormVersions.MENA_FORM)
        return ieltsPageName.MENA_DEMO_MC_FORM_1;
      if (formVariant == FormVersions.ONBOARDING_QUESTION_ADD)
        return ieltsPageName.ONBOARDING_DEMO_MC_FORM_1;
      return ieltsPageName.DEMO_MC_FORM_1;
    } else if (step == 2) {
      if (formVariant == FormVersions.MENA_FORM)
        return ieltsPageName.MENA_DEMO_MC_FORM_2;
      if (formVariant == FormVersions.ONBOARDING_QUESTION_ADD)
        return ieltsPageName.ONBOARDING_DEMO_MC_FORM_2;
      return ieltsPageName.DEMO_MC_FORM_2;
    } else if (step == 3) {
      if (formVariant == FormVersions.ONBOARDING_QUESTION_ADD)
        return ieltsPageName.ONBOARDING_DEMO_MC_FORM_3;
    }
    return '';
  };

  useEffect(() => {
    autonextDecision(
      duplicateFormValues,
      formJson,
      currentFormStep,
      setCurrentFormStep,
      isClickingBack,
    );
    return () => {
      clearTimeout(delayForStepChange.current);
    };
  }, [duplicateFormValues]);

  useEffect(() => {
    forceAutonextDecision(
      formJson,
      currentFormStep,
      formValues,
      setCurrentFormStep,
      isClickingBack,
    );
    if (currentFormStep === 2) {
      trackClickEventHandler(1);
    }
  }, [currentFormStep]);

  useEffect(() => {
    if (currentFormStep == 1)
      trackPageV2(getIeltsPageName(currentFormStep), {
        leadSource,
      });
    else if (currentFormStep == 2)
      trackPageV2(getIeltsPageName(currentFormStep), { leadSource });
    else if (currentFormStep == 3)
      trackPageV2(getIeltsPageName(currentFormStep), { leadSource });
  }, [currentFormStep]);

  const getFormId = (): string => {
    if (formVariant == FormVersions.MENA_FORM)
      return IeltsFormIds.MENA_IELTS_DEMO_FORM;
    if (formVariant == FormVersions.ONBOARDING_QUESTION_ADD)
      return IeltsFormIds.ONBOARDING_FORM;
    return IeltsFormIds.CONTEXTUAL_FORM_V1;
  };

  const handleSaveData = async (values: any) => {
    const utmDetails = getUtmData(null);
    const newValues = { ...utmDetails, ...cloneDeep(values) };
    newValues.leadSource = leadSource ?? LeadSources.OTHER;
    newValues.alias = router.query[demoAliasQueryConstant] as string;
    newValues.formId = getFormId();

    if (newValues?.name) {
      newValues.name = nameExtraSpacetriming(newValues?.name);
    }
    if (newValues?.howLeapHelp) {
      newValues.howLeapHelp = newValues.howLeapHelp.join(',');
    }

    const utmObject: any = {
      utm_campaign: utmDetails?.utmCampaign,
      gclid: utmDetails?.gclid,
      adName: utmDetails?.adName,
      utm_source: utmDetails?.utmSource,
      utm_term: utmDetails?.utmTerm,
      utm_medium: utmDetails?.utmMedium,
      campaign_type: utmDetails?.campaignType,
      ad_id: utmDetails?.adId,
    };

    if (loggedInNumber) {
      newValues.phone = loggedInNumber;
    }
    const country = getCountryFromCountryCode(newValues.countryCode);
    if (country !== null && country !== CountryCode.INDIA) {
      await ieltsPlusApi.postQeFormDataApiBeforLogin({
        ieltsEliteFormDto: { ...newValues, country },
        utmDetailDto: utmDetails,
      });
    }

    await trackIeltsFormAsync('Form Submitted', { ...newValues, ...utmObject });
    const formValuesString = getQueryParamStringFromObject(newValues);
    let redirectURL = `${navRoutes.IELTS_ELITE_SLOTS_SCREEN}?prefilled_phone=${newValues.phone}&flowType=Elite Demo&alias=masterclass`;
    if (
      newValues.signinPurpose === SIGN_IN_PURPOSE.RECORDED_CLASSES &&
      newValues.whenIelts !== WHEN_IELTS.BOOKED_EXAM &&
      router.query.form_version === FormVersions.ONBOARDING_QUESTION_ADD
    ) {
      const data = await fetchLiyRedirectData();
      if (data?.redirectUser) {
        redirectURL = `${navRoutes.IELTS_LIY_VALUE_PROPS}?prefilled_phone=${newValues.phone}&flowType=Elite Demo&alias=masterclass`;
      }
    }

    const utmString = getQueryParamStringFromObject(utmObject);
    const finalString = redirectURL + utmString + formValuesString;
    redirectOutFromIframe(finalString);
  };

  const trackClickEventHandler = (step: number) => {
    const widgetName =
      formVariant == FormVersions.ONBOARDING_QUESTION_ADD
        ? 'Inpage Form - Onboarding Purpose'
        : `Inpage Form ${step}`;
    trackIeltsClick(getIeltsPageName(step), {
      contentName: 'Next ',
      contentFormat: 'Button',
      widgetName,
      widgetFormat: 'Button',
    });
  };

  const savePersonalInfo = async (values: Record<string, any>) => {
    if (currentFormStep == formJson?.page_distribution?.length) {
      const saveValue = { ...prefilledData, ...values };
      if (formJson?.page_distribution?.length == 1) {
        trackClickEventHandler(1);
      } else {
        trackClickEventHandler(2);
      }
      if (onSubmitForm) onSubmitForm(saveValue);
      else handleSaveData(saveValue);
    } else {
      setCurrentFormStep((step) => step + 1);
    }
  };

  const getCtaText = () => {
    if (currentFormStep == formJson?.page_distribution?.length) {
      return 'Select MasterClass Slot';
    } else {
      return 'Next';
    }
  };

  const addCountryCode = (fields: any) => {
    if (router.query.form_version === FormVersions.MENA_FORM) {
      return {
        ...fields,
        countryCode:
          countryOptions.find((country) => country.value == userCountry)
            ?.value || countryOptions.length > 1
            ? countryOptions[0].value
            : countryData[CountryCode.INDIA].code,
      };
    }
    return fields;
  };

  const customValidation = (values: Record<string, any>): ValidationErrors => {
    if (router.query.form_version === FormVersions.MENA_FORM) {
      return customValidationForCountryCode(values, formJson);
    }
    return {};
  };

  return (
    <div className="w-full">
      <LinearProgressBar
        width={Math.round(
          (currentFormStep / formJson?.page_distribution?.length) * 100,
        )}
      />
      <FormHeading
        currentFormStep={currentFormStep}
        onBackClick={() => {
          setCurrentFormStep((step) => step - 1);
          setDuplicateFormValues({});
          setIsClickingBack(true);
        }}
        formVersion={router.query.form_version}
      />
      <div className="flex justify-center w-full px-4">
        <Form
          onSubmit={savePersonalInfo}
          initialValues={addCountryCode(prefilledData)}
          validate={customValidation}
        >
          {({ handleSubmit, values, valid, errors, touched, modified }) => {
            setFormValues(values);
            return (
              <div className="w-full max-w-[650px]">
                <form onSubmit={handleSubmit} noValidate>
                  {formJson?.fields
                    .slice(
                      indexCal(formJson, currentFormStep)[0],
                      indexCal(formJson, currentFormStep)[1],
                    )
                    .map((field: any, index: number) => {
                      const {
                        type,
                        options,
                        question_text,
                        fieldValue,
                        label,
                        validation,
                        default_visibility,
                        visibility_dependency,
                        numGridColumns,
                        isMultiSelect,
                        maxSelect,
                      } = field;
                      return (
                        <div key={`RenderFields${index}`}>
                          {RenderFields({
                            component_type: type,
                            question_text,
                            options,
                            validation,
                            fieldValue,
                            label,
                            default_visibility,
                            visibility_dependency,
                            values,
                            numGridColumns,
                            isMultiSelect,
                            maxSelect,
                            delayForStepChange,
                            duplicateFormValues,
                            formJson,
                            isLoggedIn,
                            setDuplicateFormValues,
                            setIsClickingBack,
                            classicForm: true,
                            errors,
                            touched,
                            valid,
                            modified,
                          })}
                        </div>
                      );
                    })}
                </form>
                <div className="w-full flex justify-center mt-12 rounded-[4px] px-2 ">
                  <Button
                    variant="primary"
                    customCss={`rounded md:w-[70%] w-full`}
                    onClick={() => {
                      handleSubmit();
                    }}
                    isDisable={!valid}
                    disableCss="bg-[#BBB7FF] text-[#FFF]"
                  >
                    <div className="flex gap-1 items-center justify-center py-1">
                      <p>{getCtaText()}</p>
                      <Image
                        src={`${imageBasePath}/avataran_assets/images/ielts/whitearrow_right.svg`}
                        height={20}
                        width={20}
                        alt="arrow"
                      />
                    </div>
                  </Button>
                </div>
              </div>
            );
          }}
        </Form>
      </div>
    </div>
  );
};

export default DemoProgressiveForm;
